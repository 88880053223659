* {
  border: 0px;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}
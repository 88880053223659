.flip-container {
  perspective: 1000px;
  border-radius: 360px;
}
.flip-card {
  height: 140px;
  width: 140px;
  position: relative;
  transform-style: preserve-3d;
  animation: flipOp 900ms 1 forwards;
}
.flip-container:hover .flip-card {
  animation: flip 900ms 1 forwards;
}
.flip-container:hover .flip-card .flip-content {
  /* animation: flipContent 1s 1 forwards; */
}
.flip-card .flip-content {
  text-align: center;
  backface-visibility: hidden;
  /* animation: flipContentOp 1s 1 forwards; */
}
.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}
.flip-card-back {
  transform: rotateY(180deg);
}
@keyframes flip {
  0% {
    transform: rotateY(0);
  }
  15% {
    transform: rotateY(45deg);
  }
  85% {
    transform: rotateY(-200deg);
  }
  100% {
    transform: rotateY(-180deg);
  }
}
@keyframes flipOp {
  0% {
    transform: rotateY(-180deg);
  }
  15% {
    transform: rotateY(-215deg);
  }
  85% {
    transform: rotateY(20deg);
  }
  100% {
    transform: rotateY(0);
  }
}
@keyframes flipContent {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(20%);
  }
  85% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes flipContentOp {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-20%);
  }
  85% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0%);
  }
}
